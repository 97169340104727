import React, { Component } from 'react';
import * as d3 from "d3";
import * as queue from "d3-queue";

import AnimatedEye from './AnimatedEye';

class Section1 extends Component {

    componentDidMount() {
        window.queue = queue;

        const debug = false;

        let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        let step = 1; // visible frame
        let targetStep = 1; // frame to animate to
        const eyeImages = [];
        const corneaImages = [];
        const totalFrames = 13;

        let ts = Math.floor(Date.now() / 100);

        let mouseX = 0;
        let autoplay = false;
        let reverse = false;

        const requestAnimFrame = (function(){ // reduce CPU consumption, improve performance and make this possible
            return  window.requestAnimationFrame       ||
                    window.webkitRequestAnimationFrame ||
                    window.mozRequestAnimationFrame    ||
                    window.oRequestAnimationFrame      ||
                    window.msRequestAnimationFrame     ||
                    function( callback ){
                      window.setTimeout(callback, 1000 / 60);
                    };
          })();

        function changeFrame(thisStep) {
            if(eyeImages.length > 0 && eyeImages[thisStep]) { // if the image exists in the array
                if(eyeImages[thisStep].complete) { // if the image is downloaded and ready
                    if(d3.select('#video-ojo').attr('src') !== eyeImages[thisStep].src) { // save overhead...?
                        d3.select('#video-ojo').attr('src', eyeImages[thisStep].src);
                        d3.select('#video-ojo-blanco').attr('src', corneaImages[thisStep].src);
                    }
                }
            }
        }

        function pad(number, length) { // pad numbers with leading zeros for JPEG sequence file names
            var str = '' + number; while (str.length < length) { str = '0' + str; } return str;
        }

        function nextFrame(frame) {
            if (reverse === true) {
                frame--;
                if (frame === 0) {
                    reverse = false;
                }
            } else {
                frame++;
                if (frame === 13) {
                    reverse = true;
                }
            }
            return frame;
        }

        function difference() {
            let now = Math.floor(Date.now() / 100);
            let diff = now - ts;
            if (diff > 3) {
                ts = now;
                return true;
            }
            return false;
        }

        (function animloop(){ // the smoothest animation loop possible
            requestAnimFrame(animloop);
            if (autoplay === true && difference()) {
                step = nextFrame(step)
            }
            if (autoplay === false) {
                step = mouseX;
            }
            if (debug === true) {
                d3.select('#debug').select('.first-line').html(step + '->' + targetStep);
                d3.select('#debug').select('.second-line').html(autoplay + ' ' + mouseX);
            }

            changeFrame(step);
        })();

        for(let i = 1; i < totalFrames + 1; i++) {
			eyeImages[i] =  new Image();
            corneaImages[i] = new Image();
			eyeImages[i].src = "./imgs/ojo/"+pad(i, 4)+".jpg";
            corneaImages[i].src = "./imgs/cornea/"+pad(i, 4)+".png";
		}

        var lookingFrames = d3.scaleLinear().domain([0, w]).range([6, 13]);
        var staringFrames = d3.scaleLinear().domain([0, w]).range([1, 5]);

        let eyePosition = document.getElementById('video-ojo').getBoundingClientRect().top;
        let eyeHeight = document.getElementById('video-ojo').height;

        const updateMouseX = function(e) {
            autoplay = false;
            if (e.clientY > eyePosition + eyeHeight/2) {
                mouseX = parseInt(lookingFrames(e.pageX));
            } else {
                mouseX = parseInt(staringFrames(e.pageX));
            }
            setTimeout(() => {
                autoplay = true;
            }, 3000);
        }

        const checkResize = function() {
            w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            // console.log("---" + w);

            eyePosition = document.getElementById('video-ojo').getBoundingClientRect().top;
            eyeHeight = document.getElementById('video-ojo').height;

            lookingFrames = d3.scaleLinear().domain([0, w]).range([6, 13]);
            staringFrames = d3.scaleLinear().domain([0, w]).range([1, 5]);

            if (w === 0)
                setTimeout(checkResize, 1000);
        }

        checkResize();

        window.addEventListener('resize', checkResize);

        d3.select('#section1').on('pointermove', updateMouseX);

    }
    render() {
        const classes = this.props.classes;
        return (
            <React.Fragment>
                <AnimatedEye classes={classes}/>
            </React.Fragment>
         );
    }
}

export default Section1;
