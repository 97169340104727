import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00"
        },
        sea: {
            light: "#18CBD6",
            main: "#55f",
            dark: "#253E75"
        },
        earth: {
            main: "#633827"
        },
        dark: {
            main: "#14231c"
        },

    },
    background: {
        default: '#fff'
    }
})

export default theme
