import React, { Component } from 'react';
import Section1 from './Section1';

import * as d3 from "d3";

class MainFrame extends Component {

    constructor(props) {
        super(props);
        this.element = React.createRef();
        this.sectionsTemplate = React.createRef();
        this.state = {
            debug: false,
            scroll: 0,
            locations: [0],
            section: 0,
            w: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
            h: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
            ratio: "",
        };
    }

    scrollToSection = (section) => {
        const sectionsTemplate = this.sectionsTemplate;
        if (sectionsTemplate.current) {
            const element = document.getElementById('MainTemplate')
            const scroll = this.state.scroll;
            const target = this.state.locations[section];
            const offset = target - scroll;

            d3.selectAll('#section1, #section2, #section3, #section4').style('scroll-snap-align', 'none');

            d3.transition()
                .delay(200)
                .duration(2000)
                .tween("scroll", scrollTween(offset))
                .on('end', function() {
                    d3.selectAll('#section1, #section2, #section3, #section4').style('scroll-snap-align', 'start');
                    // console.log('fin');
                })
                
                

            function scrollTween(offset) {
                return function() {
                    var i = d3.interpolateNumber(scroll || document.documentElement.scrollTop, offset);
                    return function(t) { 
                        element.scrollTo(0, i(t));
                    };
                };
            }
        }
    }

    updateSection = () => {
        let section = this.state.section;
        for (let index = 0; index < this.state.locations.length; index++) {
            const element = this.state.locations[index];
            if (element === this.state.scroll) {
                section = index;
            }
        }
        this.setState({
            section: section,
        }, () => {
            if (this.state.debug === true) {
                d3.select('#debug').select(".fourth-line").html("section: " + section + " " + this.state.locations);
            }
        });
    }

    updateScroll = (scroll) => {
        this.setState({
            scroll: scroll,
        }, () => {
            this.updateSection();
            if (this.state.debug === true) {
                d3.select('#debug').select(".third-line").html("scroll: " + scroll);
            }
        });
    }

    updateLocations = () => {
        let locations = [0];
        if (this.sectionsTemplate.current) {
            locations = this.sectionsTemplate.current.getSectionLocations();
        }
        this.setState({
            locations: locations,
        });
    }

    updateSizeAndRatio = (w, h) => {
        const format = d3.format(".2f");
        let ratio;
        let value = format(w / h);
        if (w < h / 2) {
            ratio = value + ":2";
        } else if (h < w / 2) {
            ratio = value + ":1";
        } else {
            ratio = value + ":1";
        }
        this.setState({
            w: w,
            h: h,
            ratio: ratio
        }, () => {
            if (this.state.debug === true) {
                let size = w + " x " + h;
                d3.select('#debug').select(".first-line").html(ratio);
                d3.select('#debug').select(".second-line").html(size);

            }
        });
    }

    resize = () => {
        window.scrollTo(0,1);
        let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.updateSizeAndRatio(w, h);
        this.updateLocations();
        this.updateScroll(this.state.scroll);

        if (this.state.scroll !== this.state.locations[(this.state.section)]) {
            if (this.sectionsTemplate.current) {
                document.getElementById('MainTemplate').scrollTo(0, this.state.locations[(this.state.section)])
            }
        }
    }

    componentDidMount() {
        window.onresize = this.resize;
        this.resize();
    }

    render() {
        const classes = this.props.classes;

        return (
            <div>
                <div id="section1" className={`${classes.section1} ${classes.eachSection}`}>
                    <Section1 classes={classes}/>
                </div>
            </div>
         );
    }
}

export default MainFrame;
