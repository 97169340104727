import theme from '../theme';
import { makeStyles } from '@material-ui/core/styles';

const materialClassFn = (theme) => {
    return (theme) => ({
        // UTILS
        overlapContainer: {
            display: "grid",
            gridTemplateColumns: "1fr",
        },
        overlapped: {
            gridRowStart: "1",
            gridColumnStart: "1",
        },
        multiply: {
            mixBlendMode: "multiply",
        },
        // MAIN TEMPLATE
        sectionsContainer: {
            scrollSnapType: "y mandatory",
            overflowY: "scroll",
            height: "100vh",
            width: "100vw"
        },
        eachSection: {
            scrollSnapAlign: "start",
            width: "calc(100%)",
            height: "100%",
            fontSize: "1rem",
            overflow: "hidden",
        },
        // SECTION 1
        section1: {
            background: "transparent",
            color: "black",
            height: "100vh",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
        },
        videoContainer: {
            backgroundColor: '#fc0',
            gridColumnStart: 'start',
            gridColumnEnd: 'end',
            gridRowStart: 'start',
            gridRowEnd: 'end',
            width: "100%",
            height: "100%",
            display: "grid",
            gridTemplateColumns: "1fr",
            '& #video-ojo': {
                position: "absolute",
                gridRowStart: "1",
                gridColumnStart: "1",
                width: "100%",
                height: "100%",
                mixBlendMode: "multiply",
                zIndex: "5",
                objectFit: "contain",
            },
            '& #video-ojo-blanco': {
                position: "absolute",
                gridRowStart: "1",
                gridColumnStart: "1",
                width: "100%",
                height: "100%",
                zIndex: "1",
                objectFit: "contain",
            },
        },
    });
};

const useStyles = makeStyles(materialClassFn(theme));

export default useStyles;
